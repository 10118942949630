import React from 'react';
import { ThemeProvider } from './src/context/ThemeContext';
import { TerminalContextProvider } from '@marijoo/react-terminal';

export const onServiceWorkerUpdateReady = () => window.location.reload();

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <TerminalContextProvider>{element}</TerminalContextProvider>
  </ThemeProvider>
);
