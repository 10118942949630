export const getOpenTerminalChar = () => {
  let char = 'k';

  // Firefox on Mac OS
  if (
    navigator &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('Mac OS X') !== -1 &&
    navigator.userAgent.indexOf('Firefox') !== -1
  ) {
    char = 'e';
  }

  // Windows
  if (
    navigator &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('Mac OS X') === -1
  ) {
    char = 'z';
  }

  return char;
}
