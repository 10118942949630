import React, { useState, useEffect } from 'react';
import { getOpenTerminalChar } from '@/helpers/terminal';

const ThemeContext = React.createContext<{
  light: boolean;
  setLight: (on: boolean) => void;
  toggleLight: () => void;
  menuActive: boolean;
  setMenuActive: (on: boolean) => void;
  toggleMenu: () => void;
  terminalActive: boolean | null;
  setTerminalActive: (on: boolean | null) => void;
  toggleTerminal: () => void;
  disableLightTheme: boolean;
  setDisableLightTheme: (disabled: boolean) => void;
}>({
  light: false,
  setLight: (on: boolean) => {
    on;
  },
  toggleLight: () => {},

  menuActive: false,
  setMenuActive: (on: boolean) => {
    on;
  },
  toggleMenu: () => {},

  terminalActive: null,
  setTerminalActive: (on: boolean | null) => {
    on;
  },
  toggleTerminal: () => {},
  disableLightTheme: false,
  setDisableLightTheme: (disabled: boolean) => {
    disabled;
  },
});

const ThemeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [light, setLight] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [terminalActive, setTerminalActive] = useState<boolean | null>(null);
  const [disableLightTheme, setDisableLightTheme] = useState(false);

  const handleEscape = (e: KeyboardEvent) => {
    if (menuActive && e.key === 'Escape') {
      setMenuActive(false);
    }
  };

  const handleTerminalKeydown = (e: KeyboardEvent) => {
    let char = getOpenTerminalChar();

    if ((e.metaKey || e.ctrlKey) && e.key === char) {
      setTerminalActive(true);
    }

    if (terminalActive && e.key === 'Escape') {
      setTerminalActive(false);
    }
  };

  useEffect(() => {
    if (light) {
      document.documentElement.classList.remove('dark');
    } else {
      document.documentElement.classList.add('dark');
    }
  });

  useEffect(() => {
    const storedLight = localStorage.getItem('light');

    if (storedLight) {
      setLight(!!JSON.parse(storedLight));
    }
  }, []);

  useEffect(() => {
    if (!light) localStorage.removeItem('light');
    else localStorage.setItem('light', JSON.stringify(light));
  }, [light]);

  useEffect(() => {
    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [menuActive]);

  useEffect(() => {
    window.addEventListener('keydown', handleTerminalKeydown);

    return () => {
      window.removeEventListener('keydown', handleTerminalKeydown);
    };
  }, [terminalActive]);

  return (
    <ThemeContext.Provider
      value={{
        light,
        setLight: (on: boolean) => setLight(on),
        toggleLight: () => setLight(disableLightTheme ? false : !light),

        menuActive,
        setMenuActive: (on: boolean) => setMenuActive(on),
        toggleMenu: () => setMenuActive(!menuActive),

        terminalActive,
        setTerminalActive: (on: boolean | null) => setTerminalActive(on),
        toggleTerminal: () => setTerminalActive(!terminalActive),
        disableLightTheme,
        setDisableLightTheme: (disabled: boolean) =>
          setDisableLightTheme(disabled),
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;

export { ThemeProvider };
