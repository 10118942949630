module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"resources/images/icon.png","name":"ehex","short_name":"ehex","start_url":"/","background_color":"#00051A","theme_color":"#00051A","display":"minimal-ui","icons":[{"src":"/appicons/ehex-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/appicons/ehex-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/appicons/ehex-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/appicons/ehex-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/appicons/ehex-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/appicons/ehex-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/appicons/ehex-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/appicons/ehex-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dbb0f3f10dc971dc391c8e5081325de7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(_blank|external)"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"ehex.de:~$","exclude":["**/404/**","**/404.html"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
